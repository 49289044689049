.todo {
  color:#ffffff;
  background-color:green;
  display:block;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  alignment: center;
  background: white;
  margin: 0 auto;
  width: 100%;
}

.pageTitleContainer {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-left: 0.5rem;
  border-left: 3px solid transparent;
}

.pageTitle {
  display: inline;
}

.autoSaveMessageContainer {
  color: #6f6f6f;
  padding-left: 1rem;
}

.autoSaveWarning {
  color: orange;
  font-weight: bold;
}

.autoSaveOK {
  color: green;
  font-weight: bold;
}

.autoSaveError {
  color: red;
  font-weight: bold;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  align-items: stretch;
}


.header {
  height: 90px;
  width: 100%;
  background: #6e2a81;
  text-align: center;
  display: flex;
  padding: 15px;
  align-items: center;
  vertical-align: middle;
}

.header h1 {
  font-size: 18px;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 1rem;
  font-weight: normal;
  text-shadow: 0 0 1px white;
}

.breadcrumb {
  height:30px;
  padding: 15px 50px;
  background:#e0268c;
  width: 100%;
  color: white;
}

.rotate {
  transform: rotate(30deg);
}

.breadcrumb a {
  padding-right:5px;
}

.main {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  display:inline-block;
  vertical-align:top;
}

.footer {
  width: 100%;
  height: 100px;
  align-items: center;
  background: #6e2a81;
  margin: 0;
  border: 0;
}

.instruction {
  line-height: 1.5;
  font-size: 0.9rem;
  color: #6f6f6f;

}

.important {
  color: red;
}

.fieldError {
  color:red;
}

.leftPanel {
  min-width: 120px;
  float: left;
  background-color: #ebebeb;
  border-right: 1px solid #e0268c;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.leftPanel a {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  padding-top: 0.4rem;
  color: #6f6f6f;
  font-size: 0.9rem;



/*  border-bottom: 1px solid #cccccc; */
}

.leftPanel a.linkActive {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  padding-top: 0.4rem;
  color: white;
  background-image:
          radial-gradient(circle at 105% 50%, rgb(255,255,255) 0.8rem, #e0268c 0.8rem);
 /* border-right: 1px solid white;*/
  margin-right: -1px;
}


div.linkActive {
display: inline-block;
  width: 20px;
  background-color: white;
}


.leftPanel a:hover {
  color: white;
  background-color: #e0268c;

}
.leftPanel a.linkActive:hover {
  color: white;
  cursor: initial;
}

div.autosave {
  float: top;
  position: fixed;
  top: 200px;
  left: 600px;
}

.rightPanel {
  float:right;
 width: 100%;

}

.main a {
     color: #e0268c;
      font-weight: bold;
}
.main a:hover {
  color: white;
  background-color: #e0268c;

}

.tooltipIcon {
  position: relative;
  top: 3px;
  margin-left: 0.5rem;
  cursor: help;
}

.tooltipIcon:hover {
  color: #e0268c;

}


.infoButton {
  transition: all 0.15s ease-out 0s;
  color: #6e2a81;
  margin-left: 0.5rem;
  font-size: 20px;
  vertical-align: bottom;
}

.infoButton:hover {
  color: #e0268c;
}


.pointer {
  cursor: pointer;

}

.addButton, .removeButton {
  height: 20px;
  width: 20px;
  line-height: 20px;

  display: inline-block;
  padding: 1px 0 0 0;
  transition: all 0.15s ease-out 0s;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #6e2a81;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}



.addButton:hover {
  background-color: #e0268c;
}
.removeButton:hover {
  background-color: #e0268c;
}


button {
  border: 0px solid transparent;
  margin: 0;
  padding: 0;

}



.pivotTitle {
  font-size: 1rem;
  color: red  !important;
}

a.next, a.bigLink, span.bigLink {
  color: white;
  transition: all 0.15s ease-in-out 0s;
  align-items: center;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: inherit;
  font-size: 100%;
  font-size: .875rem;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: .16px;
  line-height: 1.28572;
  margin: 0;
  max-width: 20rem;
  min-height: 3rem;
  outline: none;
  padding: calc(.875rem - 3px) 63px calc(.875rem - 3px) 15px;
  position: relative;
  text-align: left;
  text-decoration: none;
  vertical-align: baseline;
  vertical-align: top;

}






.logo {
  display: block;
}

.titleBox {
  margin: 0;
  color: white;
}

/* Containers set the spacing requirements around our links */


.bigLinkContainer {
  padding: 15px 0 15px 0;
  display: inline-block;
}

.nextContainer {
  padding: 15px 0 15px 0;
  float: right;
}

.next, .bigLink {
  font-weight: bold;
  font-size: 1rem;
  background-color: #6e2a81;
  border: 0px solid transparent;
  padding: 15px 20px 15px 50px;
  color: white;
  min-width: 170px;
  display: block;
  text-align: right;
}

.bigDisabledLink {
  font-weight: bold;
  font-size: 1rem;
  background-color: #cccccc;
  border: 0px solid transparent;
  padding: 15px 20px 15px 50px;
  color: white;
  min-width: 170px;
  display: block;
  text-align: right;

}

.next:hover, .bigLink:hover {
  background-color: #e0268c;
}

label.question, div.question {
  margin-bottom: 1.5rem;
  display: block;
  border-left: 3px solid #cccccc;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

span.question {
  color: #000000;
  font-weight: normal;
  font-size: 1rem;
  display:block;
  margin-bottom:0.5rem;
}

.questionWarn {
  border-left: 3px solid #e64313 !important;
}

.questionValid {
  border-left: 3px solid #09bc54 !important;
}

span.example {
  color: #6f6f6f;
  display:block;
  font-style: italic;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
  font-size: 0.75rem
}

.fileUploadSurround {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  border-color: #6e2a81;
  border-width: 2px;
  border-style: solid;
  width: 70%;
  line-height: 1.2rem;
  padding: 0.2rem;
  margin-bottom: 0.1rem;
  background-color: #ebebeb;
  outline: none;
  color: white;
  content: "Select your upload document";
}





/**
Input elements
 */
.inputText {
  width: 70%;
  max-width: 500px;
  line-height: 1.5rem;
  padding: 0.2rem 0.2rem 0.2rem 0.4rem;
  margin-bottom: 0.1rem;
}
.inputText:focus{
  border-color: #e0268c;
}
.upload {

}
/*textarea*/
.inputShort {
  width: 100%;
  line-height: 1.5rem;
  padding: 0.2rem 0.2rem 0.2rem 0.4rem;
  height: 4rem;
  overflow-x: hidden;
  overflow-y: auto;
  resize: vertical;


}
.inputShort:focus{
  border-color: #e0268c;
}
.inputLong {
  width: 100%;
  line-height: 1.5rem;
  padding: 0.2rem 0.2rem 0.2rem 0.4rem;
  height: 20rem;
  resize: vertical;

}
.inputLong:focus, .newNote:focus {
  border-color: #e0268c;
}

.newNote {
  width: 100%;
  line-height: 1.5rem;
  padding: 0.5rem 11rem 0.2rem 0.2rem;
  height: 3.5rem;

}




.noteAddIcon {
  font-size: 50px;
  color: #6e2a81;
  cursor: pointer;
  position: relative;
  top: -62px;
  left: calc(100% - 180px);
  padding: 5px;
}

.noteAddIcon svg {
  margin-bottom: 5px;
}

.noteAddIcon:hover {
  color: #e0268c;
}




.radioSelect {
  appearance: none;
  position: relative;
  top: 4px;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cccccc;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;

  margin-top: 0px;
  margin-bottom: 0px;
}
.radioSelect:hover {
  background: #e0268c;
}
.radioSelect:checked {
  background: #e0268c;
}
.radioSelect:checked::before {
  height: 20px;
  width: 20px;
  position: absolute;
/*  content: '✔'; */
  display: inline-block;
  font-size: 0.75rem;
  text-align: center;
  line-height: 20px;
}
.radioSelect:checked::after {
  background: #e0268c;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}

.radioSelect::after {
  border-radius: 50%;
}







.wordCount {
  width: 100%;
  display: inline-block;
  text-align: right;
}






.listTable th {
  font-size: 0.9rem;
  border-bottom: 3px solid #cccccc;
  color: #5f5f5f;
  padding: 2px 0 2px 0;
}

.listTable td {
  color:#5f5f5f;
  font-size: 0.9rem;
  height: 37px;
  padding: 2px 0 2px 0;
}

.listTable {
  width: 100%;
  border: 0px solid transparent;
  border-collapse: collapse;
  margin-top: 15px;
}

/*Our nested buttons within the data table*/
table.dataTableNested {
  vertical-align: top;
  display: table-cell;

}

.dataTableNestedContainer {

  vertical-align: top !important;
}

.dataTableNested td {
  padding-right: unset;
  padding-left: 0.5rem !important;
  border-top: unset;
  border-bottom: unset;
}

.dataTableNested tr {
  border-top: unset;
  border-bottom: unset;
}

table.expandedRowNested td {
    padding-right: unset;
    padding-left: unset;
    border-top: unset;
    border-bottom: unset !important;
  }

table.expandedRowNested tr {
  border-top: unset;
  border-bottom: unset !important;
}

table.expandedRowNested {


}

.footerRow td {
  border-top: 3px solid #cccccc;
  color: #5f5f5f;
  padding-top: 2px;
  min-height: 37px;
  text-align: left;
}

.flexContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.nextButtonContainer {
  width: 85px;
  display: inline-block;
}

.previousButtonContainer {
  width: 85px;
  display: inline-block;
}

.tableStatsContainer {
  align-self: flex-start;
  flex-grow: 0;
}

.spaceContainer {
  flex-grow: 1;
}

.paginationButton {
  background-color: #dddddd;
  margin: 0px 2px 0px 0px;
  padding: 0 10px 0 10px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  height: 30px;
  min-width: 80px;
  transition: all 0.15s ease-out 0s;
  border: 0px solid transparent;
}

.paginationButton:hover {
  background-color: #e0268c;
}


.paginationContainer {
  flex-grow: 0;
  text-align: right;

}

.filterButton, .button30 {
  background-color: #dddddd;
  margin: 0px 2px 0px 0px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  min-width: 30px;
  min-height: 30px;
  transition: all 0.15s ease-out 0s;
  border: 0px solid transparent;

}

.filterButton:hover, .button30:hover {
  background-color: #e0268c;
}


.button30, button30Active {
  width: 30px;
  display: inline-block;
}

.center {
  text-align: center;
}

.download {
  cursor: pointer;
}

.right {
  text-align:right;
}

.left {
  text-align: left;
}

.filterButtonActive, .button30Active {
  background-color: #e0268c;
  margin: 0px 2px 0px 0px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  width: 30px;
  min-height: 30px;
  transition: all 0.15s ease-out 0s;
  border: 0px solid transparent;
}

.filterButtonActive:hover, .button30Active:hover {
  background-color: #dddddd;
}

.serverMessage {
  color: #e64313;
  border: 3px solid #e64313;
  width: 100%;
  margin-top: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}


.hidden {
  display: none;
  visibility: hidden;
}

.timeSpinnerSurround {
  position: relative;
  left: -25px;
}

.timeSpinnerContainer {
  display: inline;
}

.workingBehind {
  position:absolute;
}

.workingFore {
  stroke-dasharray: 50.3px;
  stroke-dashoffset: 50.3px;
  position:absolute;
  animation-direction: alternate;
}

.validateWarn {
  border-top: 2px solid #e64313 !important;
  border-left: 2px solid #e64313 !important;
  border-right: 2px solid #e64313 !important;
  border-bottom: 2px solid #e64313 !important;
}

.validationWarning {

  padding: 5px 5px 5px 5px;
  color: #e64313;


  border-collapse: collapse;
  font-size: 12px;
  font-weight: bold;
  /*    letter-spacing: 0.05rem; */

}

.defaultForm {
  padding-left: 0.5rem;
}



.uploadSurround {
  display: inline-block;
  margin: 15px 15px 15px 0px;
 vertical-align: middle;
}

.uploadButton {
  border: 1px solid transparent;
  color: white;
  background-color: #6e2a81;
  height: 40px;
  text-align: right;
  padding: 0 15px 0 80px;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.15s ease-out 0s;
  cursor: pointer;
  vertical-align: middle;
  display: table-cell;
}

.uploadButton:hover {
  background-color: #e0268c;
}

.noteHeader {
  margin: 0;
  padding: 5px 10px 5px 10px;
  background-color: #6e2a81;
  color: white;
  font-size: 0.9rem;
  font-weight: normal;
}

.noteClose {
  float: right;
  cursor: pointer;
}

.noteClose:hover {
  text-decoration: underline;
}

.noteContent {
  font-size: 0.9rem;
  overflow: hidden;
  margin: 0.2rem 0 0 0;
  padding: 0;
}



.noteTest {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: 2px solid #6e2a81;
  padding: 0;
  background-color: white;
  z-index: 1;
  height: 200px;
  margin-bottom: 15px;
}


.uploadTest {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: 2px solid #6e2a81;
  padding: 0;
  background-color: white;
  z-index: 1;
  height: 400px;
  margin-bottom: 15px;
}

.noteBody {
  padding: 5px 10px 5px 10px;
  height: 167px;
}

.noteList {
  display: block;
  height:90px;
  overflow-y: scroll;
  overflow-x: hidden;
}


.feedbackIcon {
  float: right;
  color: #6e2a81;
  cursor:pointer;
  font-size: 30px;
  height: 50px;
  transition: all 0.15s ease-out 0s;
}

.feedbackIcon:hover {
  color: #e0268c;

}

.documentListContainer {
  padding-left: 30px;

}

.documentList {
  list-style: none;
  line-height: 1.5;
  font-size: 0.9rem;
  color: #6f6f6f;

}

.documentList::before {
  content: "■";
  padding-right: 8px;
  color: #6f6f6f;
}

.navbarList {
  margin: 0;
  padding: 0;
}

.displayList {

  display: block;
  border: 2px solid #6e2a81;
  padding: 0;
  background-color: white;
  margin-bottom: 15px;
  height: 100%;
  overflow: auto;
}

.listOptions {
  width: 200px;
  height: 150px;
  margin: 0 1rem 0 0;
  padding: 0;
}

.assessor {
  line-height: 1.5;
  font-size: 0.9rem;
  color: #6f6f6f;
 width: 100%;

}

.assessorOutcome {
  float: right;
  /*
  background-color: #6e2a81;
  color: white;
  width: 20px;
  line-height: 1.3;

*/
  /*  width: 15px;
    height: 15px;
    color: white;
    background-color: #6e2a81;
    vertical-align: center;
    margin-top: 3px;
    padding: 0px; */
}

.inlineTooltip {
  background-color: black;

}

.questionIdentifier {
  background-color: #dddddd;
  color: #6f6f6f;
  font-size: 10px;
  padding: 0px 10px 0px 10px;
  border: 1px solid #6f6f6f;
  border-radius: 20px;
  min-width: 30px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -3px;

}


/** override to prevent scrollable content being visible in the margin at the bottom of the page **/

.ms-Panel-footer {
  background-color: white;
}