.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    background: white;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    align-items: stretch;
    flex: 1 0 auto;
}

.header {
    height: 90px;
    width: 100%;
    background: #6e2a81;
    text-align: center;
    display: flex;
    padding: 15px;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;
}

.header div {
    width: 100%;
}

.header h1 {
    font-size: 18px;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 1rem;
    font-weight: normal;
    text-shadow: 0 0 1px white;
    color: white;
}

.breadcrumbContainer {
    height:30px;
    padding-right: 15px;
    padding-top: 7px;
    background:#e0268c;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row ;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    }

.breadcrumbLeft {
    text-align: right;
    height:30px;
}

.breadcrumbRight {
    text-align: right;
    height:30px;
}

.breadcrumbContainer a {

}

.breadcrumbContainer a:before {
    content: "|";
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -2px;
    font-weight: bold;
}

.breadcrumbContainer a:first-child:before {
    content: "";
}

.breadcrumbContainer a:hover {
    text-decoration: underline;
}

.main {
    width: 100%;
    padding: 0.5rem 1rem 1rem;
    display:inline-block;
    vertical-align:top;
    flex: 1 0 auto;
}

.footer {
    width: 100%;
    height: 100px;
    background: #6e2a81;
    margin: 0;
    border: 0;
    color: white;
    padding: 15px;
    vertical-align: middle;
    flex: 0 0 auto;
}

.rightPanel {
    float:right;
    width: 100%;
}
.leftPanel {
    min-width: 150px;
    float: left;
    background-color: #ebebeb;
    border-right: 1px solid #e0268c;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.leftPanel a {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.25rem;
    padding-top: 0.4rem;
    color: #6f6f6f;
    font-size: 0.9rem;



    /*  border-bottom: 1px solid #cccccc; */
}

.leftPanel a.linkActive {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.25rem;
    padding-top: 0.4rem;
    color: white;
    background-image:
            radial-gradient(circle at 105% 50%, rgb(255,255,255) 0.9rem, #e0268c 0.9rem);
    /* border-right: 1px solid white;*/
    margin-right: -1px;
}


div.linkActive {
    display: inline-block;
    width: 20px;
    background-color: white;
}


.leftPanel a:hover {
    color: white;
    background-color: #e0268c;

}
.leftPanel a.linkActive:hover {
    color: white;
    cursor: initial;
}

#sidebarOptionalElements {
    visibility: hidden;
    display: none;
}

.navbarList {
    margin: 0;
    padding: 0;
}

.navbarList li {
    margin: 0 0 0 15px;
    padding: 0;
}

.navbarList li.dontShow {

   list-style: none;
}


