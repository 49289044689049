/**
Styles for any modal informational dialogues primarily via the Popup component
*/





.infoPopup {
    width: 340px;
    height: 100%;
    border: 1px solid black !important;
}

.infoPopupHeader {
    height: 1rem;
    width: 100%;
    background: #6e2a81;
    text-align: center;
    display: flex;
    padding: 1rem;
    align-items: center;
    vertical-align: middle;
    margin: 0px;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
}

.infoPopupBreadcrumb {
    height: 1rem;
    width: 100%;
    background-color: #e0268c;
}

.infoPopupBody {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: #ffffff;
    margin: 0px;
    font-size: 0.8rem;
}

.infoPopupFooter {
    height: 1rem;
    width: 100%;
    background: #6e2a81;
    text-align: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    vertical-align: middle;
}
