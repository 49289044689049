

//Global overrides

.cds--accordion__content {
  padding-right: 0 !important;

}

.cds--modal-container {
  height: 80% !important;
}
